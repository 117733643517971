import React from "react"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography"

export default function Home({ data }) {
  return (

<div>
<img  css={css`
          margin: 0 auto;
          max-width: 1000px;
          padding-top: ${rhythm(.5)};
          padding-bottom: ${rhythm(.5)};
          display: block;
          width: 700px;

        `}
        align='center' 
        src={ require('../images/PointofNews.svg')} />

<div css={css `background: rgba(211,211,211, 0.5);
              max-width: 700px;
              border: 2px solid;
              border-color: darkgrey;
              margin: 0 auto;`}
          align='center'>
      <img  css={css`
          margin: 0 auto;
          max-width: 500px;
          padding-top: ${rhythm(.5)};
          display: block;
          width: 100px;
        `}
        align='center' 
        src={ require('../images/Logo_WashMon.svg')} />
      <h1 css={css`
          margin: 0 auto;
          max-width: 1000px;
          padding-top: ${rhythm(.5)};
          padding-bottom: ${rhythm(1)};
          display: block;
          font-style: normal;
          text-shadow: 1.2px 1.2px 1.2px darkgrey;
          color: rgb(255,255,255);
          width: 700px;
        `}
          align='center' >
        Coming Soon!
      </h1>
    </div>
</div>
)

}

